import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faCirclePlus, faCopy, faUser, faInbox } from '@fortawesome/free-solid-svg-icons';
import './SideDrawer.css';

const SideDrawer = ({ threads, currentThread, onThreadSelect, onNewThread, onInviteAccept, onInviteDecline, closeDrawer, onLogout, pendingInvites, setPendingInvites }) => {
    const [showNewChatModal, setShowNewChatModal] = useState(false);
    const [newThreadName, setNewThreadName] = useState('');
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [inputThreadId, setInputThreadId] = useState('');
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const [showInboxDropdown, setShowInboxDropdown] = useState(false);
    const [selectedInvite, setSelectedInvite] = useState(null);
    const dropdownRef = useRef();
    const inboxRef = useRef();

    // Close dropdowns when clicking outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowProfileDropdown(false);
            }
            if (inboxRef.current && !inboxRef.current.contains(event.target)) {
                setShowInboxDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleNewChatSubmit = () => {
        if (newThreadName.trim()) {
            onNewThread(newThreadName);
            setShowNewChatModal(false);
            setNewThreadName('');
        }
    };

    // const handleJoinSubmit = () => {
    //     if (inputThreadId.trim()) {
    //         onJoinThread(inputThreadId);
    //         setShowJoinModal(false);
    //         setInputThreadId('');
    //     }
    // };

    const handleCopyThreadId = (threadId) => {
        navigator.clipboard.writeText(threadId).then(() => {
            console.log(`Thread ID ${threadId} copied to clipboard`);
        }).catch((err) => {
            console.error('Failed to copy thread ID:', err);
        });
    };

    const handleThreadSelect = (threadId) => {
        onThreadSelect(threadId);
        closeDrawer();
    };

    const handleInviteAction = (invite, action) => {
        if (action === 'accept') {
            onInviteAccept(invite.id);
        } else if (action === 'delete') {
            onInviteDecline(invite.id);
        }
        setSelectedInvite(null);
    };

    return (
        <div className="side-drawer">
            <div className="drawer-header">
                <div ref={dropdownRef}>
                    <FontAwesomeIcon
                        icon={faUser}
                        className="drawer-icon profile-icon"
                        title="Profile"
                        onClick={() => setShowProfileDropdown((prev) => !prev)}
                    />
                    {showProfileDropdown && (
                        <div className="profile-dropdown">
                            <button className="dropdown-item" onClick={onLogout}>Logout</button>
                        </div>
                    )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div ref={inboxRef}>
                        <FontAwesomeIcon
                            icon={faInbox}
                            className="drawer-icon inbox-icon"
                            title="Inbox"
                            onClick={() => setShowInboxDropdown((prev) => !prev)}
                        />
                        {showInboxDropdown && (
                            <div className="profile-dropdown inbox-dropdown">
                                {pendingInvites.length > 0 ? (
                                    pendingInvites.map((invite) => (
                                        <button key={invite.id} className="dropdown-item" onClick={() => setSelectedInvite(invite)}>
                                            {invite.threadName}
                                        </button>
                                    ))
                                ) : (
                                    <button className="dropdown-item no-invites">No pending invites</button>
                                )}
                            </div>
                        )}
                    </div>

                    <FontAwesomeIcon
                        icon={faCirclePlus}
                        className="drawer-icon"
                        onClick={() => setShowNewChatModal(true)}
                        title="New Chat"
                    />
                </div>
            </div>

            <ul className="thread-list">
                {threads.map((thread) => (
                    <li
                        key={thread.id}
                        className={thread.id === currentThread ? 'thread-item selected' : 'thread-item'}
                    >
                        <span onClick={() => handleThreadSelect(thread.id)} className="thread-name">
                            {thread.name}
                        </span>
                    </li>
                ))}
            </ul>

            {/* New Chat Modal */}
            {showNewChatModal && (
                <div className="modal-backdrop" onClick={() => setShowNewChatModal(false)}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h3>Create New Chat</h3>
                        <input
                            type="text"
                            value={newThreadName}
                            onChange={(e) => setNewThreadName(e.target.value)}
                            placeholder="Enter Chat Name"
                            onKeyDown={(e) => e.key === 'Enter' && handleNewChatSubmit()}
                        />
                        <div className="modal-actions">
                            <button onClick={handleNewChatSubmit}>Create</button>
                            <button onClick={() => setShowNewChatModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Invite Modal */}
            {selectedInvite && (
                <div className="modal-backdrop" onClick={() => setSelectedInvite(null)}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h3>Join {selectedInvite.threadName}?</h3>
                        <div className="modal-actions">
                            <button onClick={() => handleInviteAction(selectedInvite, 'accept')}>Accept</button>
                            <button onClick={() => handleInviteAction(selectedInvite, 'delete')}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SideDrawer;
