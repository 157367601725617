import React, { useContext, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { WebSocketContext } from './WebSocketProvider';
import { Buffer } from 'buffer';
import './MessageList.css';
// import { clear } from 'console';

const MessageList = forwardRef(({ sessionId, currentThreadId, userId, contacts, sendInvite }, ref) => {
    const { messages, setMessages, imageFetch } = useContext(WebSocketContext);
    const messageListRef = useRef();
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [userToInvitePhone, setUserToInvitePhone] = useState('');
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [fullscreenImageId, setFullscreenImageId] = useState(null);

    const clearMessages = () => {
        setMessages([]);
    };

    const closeImage = () => {
        setMessages((prev) => prev.map((msg) => {
            if (msg.imageId === fullscreenImageId) {
                return {
                    ...msg,
                    image: null,
                    placeholder: true,
                    holding: false
                };
            } else {
                return msg;
            }
        }));
        setFullscreenImage(null);
        setFullscreenImageId(null);
    }

    useImperativeHandle(ref, () => ({
        clearMessages
    }));

    // Automatically scroll to the latest message
    useEffect(() => {
        const messageList = messageListRef.current;
        if (messageList) {
            const isAtBottom =
                messageList.scrollHeight - messageList.scrollTop === messageList.clientHeight;
            if (isAtBottom) {
                messageList.scrollTop = messageList.scrollHeight;
            }
        }
    }, [messages]);

    const handleInviteUser = () => {
        console.log('Add User');
        sendInvite(userToInvitePhone, currentThreadId);
        setUserToInvitePhone('');
        setShowInviteModal(false);
    };

    const handleImageHold = async (imageId) => {
        console.log('Fetching image...');
        setMessages((prev) => prev.map((msg) => {
            if (msg.imageId === imageId) {
                return { ...msg, holding: true };
            }
            return msg;
        }));
        // imageFetch(imageId);

        try {
            const imageBuffer = await imageFetch(imageId);
            const imageBase64 = Buffer.from(imageBuffer).toString('base64');
            setFullscreenImage(`data:image/jpeg;base64,${imageBase64}`);
            setFullscreenImageId(imageId);
            setMessages((prev) => prev.map((msg) => {
                if (msg.imageId === imageId) {
                    return { ...msg, placeholder: false };
                }
                return msg;
            }));
            // setMessages((prev) => prev.map((msg) =>
            //     msg.imageId === imageId && msg.holding
            //         ? { ...msg, image: imageBase64, placeholder: false }
            //         : msg
            // ));
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    const handleImageRelease = (imageId) => {
        // Remove the fetched image from memory
        // set placeholder to true
        // set image to null
        console.log('Removing image from memory...');
        setFullscreenImage(null);
        // setMessages((prev) => prev.map((msg) => {
        //     if (msg.imageId === imageId) {
        //         return {
        //             ...msg,
        //             image: null,
        //             placeholder: true,
        //             holding: false
        //         };
        //     } else {
        //         return msg;
        //     }
        // }));
    };

    return (
        <div className="message-list" ref={messageListRef}>
            <FontAwesomeIcon
                icon={faUserPlus}
                className="add-user-icon"
                title="Add User"
                onClick={() => setShowInviteModal((prev) => !prev)}
            />
            {messages
                .filter((msg) => msg.threadId === currentThreadId) // Only show messages in the current thread
                .slice() // Create a shallow copy to avoid mutating the original array
                .reverse() // Reverse the order to show the latest message at the bottom
                .map((msg, index, arr) => {
                    // Extract the date from datetime if available
                    const currentDate = msg.datetime ? new Date(msg.datetime).toDateString() : null;
                    const previousDate = index < arr.length - 1 ? new Date(arr[index + 1].datetime).toDateString() : null;

                    const shouldShowDataSeparator = currentDate !== previousDate;

                    const formattedDate = currentDate ? new Date(msg.datetime).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' }) : null;

                    // Extract time from datetime if available
                    const formattedTime = msg.datetime ? new Date(msg.datetime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : null;
                    return (
                        <React.Fragment key={index}>
                            <div
                                className={`message-container ${msg.userId === userId ? 'sent' : 'received'}`}
                            >
                                <div className="message-header">
                                    {msg.userId !== userId && (
                                        <div className="sender-name">{msg.sender}</div>
                                    )}
                                    {formattedTime && (
                                        <span className={`message-time ${msg.userId === userId ? 'sent-time' : 'received-time'}`}>
                                            {formattedTime}
                                        </span>
                                    )}
                                </div>
                                {msg.text && (
                                    <div className={`message ${msg.userId === userId ? 'sent' : 'received'}`}>
                                        {msg.text}
                                    </div>
                                )}
                                {msg.isImage && (
                                    <div
                                        className={`message image-message ${msg.userId === userId ? 'sent' : 'received'}`}
                                        onMouseDown={() => handleImageHold(msg.imageId)}
                                        onMouseUp={() => handleImageRelease(msg.imageId)}
                                        onTouchStart={() => handleImageHold(msg.imageId)}
                                        onTouchEnd={() => handleImageRelease(msg.imageId)}
                                        onContextMenu={(e) => e.preventDefault()}
                                        style={{ position: 'relative' }}
                                    >
                                        {msg.placeholder ? (
                                            <>
                                                <img src="/placeholder-image.jpg" alt="Placeholder" className="message-image" />
                                                {msg.holding && (
                                                    <div className="spinner-overlay">
                                                        <div className="spinner"></div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            // <img src={`data:image/jpeg;base64,${msg.image}`} alt="Fetched" className="message-image" />
                                            <img src="/placeholder-image.jpg" alt="Placeholder" className="message-image" />
                                        )}
                                    </div>
                                )}
                            </div>
                            {shouldShowDataSeparator && formattedDate && (
                                <div className="date-separator">
                                    <span>{formattedDate}</span>
                                </div>
                            )}
                        </React.Fragment>
                    )
                })}

            {fullscreenImage && (
                <div className="image-modal" onClick={closeImage}>
                    {/* <div className="modal-backdrop"> */}
                    {/* <div className="modal fullscreen-modal" onClick={handleImageRelease}> */}
                    <img src={fullscreenImage} alt="Full-screen preview" className="fullscreen-image" />
                    {/* </div> */}
                </div>
            )}
            {/* Invite Modal */}
            {showInviteModal && (
                <div className="modal-backdrop" onClick={() => setShowInviteModal(false)}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h3>Invite to Chat</h3>
                        <input
                            type="text"
                            value={userToInvitePhone}
                            onChange={(e) => setUserToInvitePhone(e.target.value)}
                            placeholder="Invitee's Phone Number"
                            onKeyDown={(e) => e.key === 'Enter' && handleInviteUser()}
                        />
                        <div className="modal-actions">
                            <button onClick={handleInviteUser}>Send</button>
                            <button onClick={() => setShowInviteModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
});

export default MessageList;



// const dateObj = new Date(receivedTimestamp);

// const formattedDate = dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
// // Example: "January 21, 2025"

// const formattedTime = dateObj.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
// Example: "02:30 PM"
