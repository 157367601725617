import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import WebSocketProvider from './WebSocketProvider';

// Dynamically set the available viewport height
const setViewportHeight = () => {
    const appHeight = window.innerHeight; // Get actual viewport height
    document.documentElement.style.setProperty('--app-height', `${appHeight}px`);
};

setViewportHeight();
window.addEventListener('resize', setViewportHeight);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
    <BrowserRouter>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </BrowserRouter>
);
