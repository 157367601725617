//App.js
import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate, useSearchParams } from 'react-router-dom';
import WebSocketProvider from './WebSocketProvider';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import SideDrawer from './SideDrawer'; // Import a side-drawer component for threads
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './App.css';

function App() {
    const [clientPhone, setClientPhone] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [userId, setUserId] = useState(null);
    const [sessionId, setSessionId] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [notification, setNotification] = useState(null); // State for notification
    const [threads, setThreads] = useState([]); // Manage the list of threads
    const [currentThreadId, setCurrentThreadId] = useState(''); // Manage the active thread
    const [drawerWidth, setDrawerWidth] = useState(200); // Initial width for the SideDrawer
    const [isMobile, setIsMobile] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [pendingInvites, setPendingInvites] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [pendingThreadId, setPendingThreadId] = useState(null);
    const pendingImages = useRef({});
    const resizerRef = useRef();
    const socketRef = useRef();
    const messageQueueRef = useRef([]);
    const messageListRef = useRef();

    useEffect(() => {
        const validateAndSetThreadId = (threadId) => {
            if (threadId && threads.some((thread) => thread.id === threadId)) {
                // If the thread ID is valid, set it as the current thread
                setCurrentThreadId(threadId);
                localStorage.setItem('lastSelectedThread', threadId); // Persist to localStorage
            } else {
                // Fallback to the last selected thread from localStorage or 'general'
                const storedThreadId = localStorage.getItem('lastSelectedThread') || 'general';
                setCurrentThreadId(storedThreadId);
                // const newUrl = `${window.location.origin}?t=${storedThreadId}`;
                // window.history.replaceState({}, '', newUrl);
            }
        };

        // Determine initial thread ID from the URL or localStorage
        // const initialThreadId = new URL(window.location.href).searchParams.get('t') ||
        const initialThreadId = localStorage.getItem('lastSelectedThread') ||
            'general';

        validateAndSetThreadId(initialThreadId);

        // const handlePopState = () => {
        //     const newThreadId = new URL(window.location.href).searchParams.get('t');
        //     validateAndSetThreadId(newThreadId);
        // };

        // window.addEventListener('popstate', handlePopState);
        // return () => window.removeEventListener('popstate', handlePopState);
    }, [threads]);

    useEffect(() => {
        if (pendingThreadId) {
            handleThreadSelect(pendingThreadId);
            setPendingThreadId(null);
        }
    }, [threads]);


    useEffect(() => {
        if (currentThreadId) {
            localStorage.setItem('lastSelectedThread', currentThreadId);
        }
    }, [currentThreadId]);

    // validates threadId is valid. If not, sets the first thread as the current thread
    useEffect(() => {
        if (!threads.some((thread) => thread.id === currentThreadId)) {
            const fallbackThreadId = threads.length > 0 ? threads[0].id : '';
            setCurrentThreadId(fallbackThreadId);
        }
    }, [threads, currentThreadId]);

    useEffect(() => {
        const storedLoggedIn = localStorage.getItem('loggedIn');
        const storedClientPhone = localStorage.getItem('clientPhone');
        const storedSessionId = localStorage.getItem('sessionId');
        const storedThreads = localStorage.getItem('threads');
        const storedUserId = localStorage.getItem('userId');
        const storedFirstName = localStorage.getItem('firstName');
        const storedLastName = localStorage.getItem('lastName');
        const storedThreadId = localStorage.getItem('lastSelectedThread') || '';

        if (storedThreads) {
            setThreads(JSON.parse(storedThreads));
        }

        if (storedThreadId) {
            setCurrentThreadId(storedThreadId);
        }

        if (storedLoggedIn === 'true' && storedClientPhone && storedSessionId && storedUserId) {
            setLoggedIn(true);
            setClientPhone(storedClientPhone);
            setSessionId(storedSessionId);
            setUserId(storedUserId);
            setFirstName(storedFirstName);
            setLastName(storedLastName);

            const sendLoginMessage = () => {
                if (socketRef.current && socketRef.current.isReady) {
                    socketRef.current.send(
                        JSON.stringify({
                            type: 'login',
                            userPhone: storedClientPhone,
                            userId: storedUserId,
                            sessionId: storedSessionId,
                        })
                    );
                    console.log('SENT LOGIN REQUEST');
                } else {
                    // Queue the message if WebSocket isn't ready
                    if (socketRef.current) {
                        messageQueueRef.current.push(
                            JSON.stringify({
                                type: 'login',
                                userPhone: storedClientPhone,
                                userId: storedUserId,
                                sessionId: storedSessionId,
                            })
                        );
                        console.log('QUEUED LOGIN REQUEST');
                    }
                }
            };

            sendLoginMessage();
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        if (loggedIn) {
            localStorage.setItem('loggedIn', 'true');
            localStorage.setItem('clientPhone', clientPhone);
            localStorage.setItem('sessionId', sessionId);
            localStorage.setItem('currentThreadId', currentThreadId);
            localStorage.setItem('userId', userId);
            localStorage.setItem('firstName', firstName);
            localStorage.setItem('lastName', lastName);
            if (threads.length > 0) {
                localStorage.setItem('threads', JSON.stringify(threads));
            }
        } else {
            localStorage.removeItem('loggedIn');
            localStorage.removeItem('clientPhone');
            localStorage.removeItem('sessionId');
            localStorage.removeItem('currentThreadId');
            localStorage.removeItem('userId');
            localStorage.removeItem('firstName');
            localStorage.removeItem('lastName');
            localStorage.removeItem('threads');
        }
    }, [loggedIn, clientPhone, sessionId, userId, firstName, lastName]);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    const handleMouseMove = (e) => {
        const newWidth = Math.min(Math.max(e.clientX, 150), 400); // Limit width between 150px and 400px
        setDrawerWidth(newWidth);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const validatePhoneNumber = (number) => {
        // At least 3 characters and no whitespace
        return number.length >= 3 && !/\s/.test(number);
    };

    const validateEmail = (email) => {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const nonEmptyField = (field) => {
        return field.length > 0;
    };

    const handleSubmit = () => {
        if (validatePhoneNumber(clientPhone)) {
            setClientPhone(clientPhone);
            setLoggedIn(true);
        }
    };

    const handleJoinedThread = (threadId, threadName) => {
        setThreads((prevThreads) => {
            const updatedThreads = prevThreads.some((thread) => thread.id === threadId)
                ? prevThreads
                : [...prevThreads, { id: threadId, name: threadName }];

            // Select the new thread immediately after updating threads
            // handleThreadSelectWithUpdatedThreads(threadId, updatedThreads);
            handleThreadSelect(threadId);
            return updatedThreads;
        });
    };


    // Helper function to select a thread with updated threads
    const handleThreadSelectWithUpdatedThreads = (threadId, updatedThreads) => {
        if (updatedThreads.some((thread) => thread.id === threadId)) {
            setCurrentThreadId(threadId);

            // Update the URL without reloading the page
            const newUrl = `${window.location.origin}?t=${threadId}`;
            window.history.pushState({ threadId }, '', newUrl);
        } else {
            console.warn('Attempted to switch to an invalid thread:', threadId);
        }
    };

    const handleThreadSelect = (threadId) => {
        setCurrentThreadId(threadId);
        // if (threads.some((thread) => thread.id === threadId)) {
        //     setCurrentThreadId(threadId);

        //     // // Update the URL without reloading the page
        //     // const newUrl = `${window.location.origin}?t=${threadId}`;
        //     // window.history.pushState({ threadId }, '', newUrl);
        // } else {
        //     console.warn('Attempted to switch to an invalid thread:', threadId);
        // }
    };

    const handleNewThread = (threadName) => {
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'new_thread',
                    threadName: threadName,
                    sessionId: sessionId,
                })
            )
        }
    };

    const handleInviteAccept = (threadId) => {

    };

    const handleInviteReject = (threadId) => {

    };

    const handleLoginAttempt = async () => {
        console.log('Send Login Message to Server');

        if (!validatePhoneNumber(clientPhone)) {
            console.error('Invalid phone number');
            return;
        }

        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'login',
                    userPhone: clientPhone,
                })
            );
            console.log('SENT LOGIN REQUEST');
        }
    };

    const handleLoginResponse = (uid, sid, firstName, lastName) => {
        console.log('Successfully logged in');

        setLoggedIn(true);
        setSessionId(sid);
        setUserId(uid);
        setFirstName(firstName);
        setLastName(lastName);
    };

    const handleRegisterAttempt = async () => {
        console.log('sending register message');
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'register',
                    userPhone: clientPhone,
                    firstName: firstName,
                    lastName: lastName,
                    userEmail: clientEmail,
                })
            );
        }
    }

    const handleRegistrationSuccess = () => {
        setNotification('Registration Successful');
        setTimeout(() => {
            setNotification(null);
        }, 5000);
    };

    const handleClearMessages = () => {
        if (messageListRef.current) {
            messageListRef.current.clearMessages();
        }
    };


    const handleLogout = () => {
        console.log('Send Logout Message to Server');
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'logout',
                    sessionId: sessionId,
                })
            );
        }
        // setMessages([]);
        handleClearMessages();
        setLoggedIn(false)
        setSessionId(null);
        setThreads([]);
        setCurrentThreadId('');
    };

    const handleThreadAdded = (threadId, threadName, forSessionId) => {

        setThreads((prevThreads) => {
            if (!prevThreads.find((thread) => thread.id === threadId)) {
                return [...prevThreads, { id: threadId, name: threadName }];
            }
            return prevThreads;
        });

        // handleThreadSelect(threadId);
        setPendingThreadId(threadId);

    };

    const handleSendInvite = (inviteePhone, targetThreadId) => {
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'invite_phone',
                    targetThreadId: targetThreadId,
                    inviteePhone: inviteePhone,
                    sessionId: sessionId,
                })
            );
        }
    };

    // const handleSendInvite = (inviteeId, targetThreadId) => {
    //     console.log('Send invite to:', inviteeId, 'for thread:', targetThreadId);
    //     if (socketRef.current) {
    //         socketRef.current.send(
    //             JSON.stringify({
    //                 type: 'invite',
    //                 targetThreadId: targetThreadId,
    //                 inviteeId: inviteeId,
    //                 sessionId: sessionId,
    //             })
    //         );
    //     }
    // };

    const handleAcceptInvite = (inviteId) => {
        // find the invite
        const invite = pendingInvites.find(invite => invite.id === inviteId);
        if (!invite) {
            console.error('Invite not found');
            return;
        }

        // send accept invite message
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'invite_accept',
                    inviteId: inviteId,
                    sessionId: sessionId,
                })
            );
            // remove invite from pending invites
            setPendingInvites((prevInvites) => prevInvites.filter(invite => invite.id !== inviteId));
        }

    };

    const handleDeclineInvite = (inviteId) => {
        // find the invite
        const invite = pendingInvites.find(invite => invite.id === inviteId);
        if (!invite) {
            console.error('Invite not found');
            return;
        }

        // send decline invite message
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'invite_decline',
                    inviteId: inviteId,
                    sessionId: sessionId,
                })
            );
            // remove invite from pending invites
            setPendingInvites((prevInvites) => prevInvites.filter(invite => invite.id !== inviteId));
        }
    };


    if (!loggedIn) {
        return (
            <WebSocketProvider socketRef={socketRef} pendingImagesRef={pendingImages} onRegistrationSuccess={handleRegistrationSuccess} messageQueueRef={messageQueueRef} setContacts={setContacts} setPendingInvites={setPendingInvites} userId={userId} sessionId={sessionId} setSessionId={setSessionId} clientPhone={clientPhone} threadId={currentThreadId} onThreadAdded={handleThreadAdded} joinThread={handleJoinedThread} clientThreads={threads} handleLoginResponse={handleLoginResponse} isLoggedIn={loggedIn}>
                {!showRegister && (
                    <div className="client-setup">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            // handleSubmit();
                            handleLoginAttempt();
                        }}>
                            <h2>Phone Number</h2>
                            <input
                                type="text"
                                value={clientPhone}
                                onChange={(e) => setClientPhone(e.target.value)}
                                placeholder="Your phone number"
                            />
                            <button type="submit" className="join-button" disabled={!validatePhoneNumber(clientPhone)}>
                                Login
                            </button>
                            {!validatePhoneNumber(clientPhone) && clientPhone && (
                                <p className="error-message">
                                    Entry not valid
                                </p>
                            )}
                            <p
                                className="register-link"
                                style={{ cursor: 'pointer', textDecoration: 'none', marginTop: '30px' }}
                                onClick={() => setShowRegister(true)}
                            >
                                Register
                            </p>
                        </form>
                    </div>
                )}
                {showRegister && (
                    <div className="client-setup">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            console.log('Registering user')
                            setShowRegister(false);
                            handleRegisterAttempt();
                        }}>
                            <h3>First Name</h3>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            // placeholder=""
                            />
                            <h3>Last Name</h3>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            // placeholder="Your phone number"
                            />
                            <h3>Phone Number</h3>
                            <input
                                type="text"
                                value={clientPhone}
                                onChange={(e) => setClientPhone(e.target.value)}
                            // placeholder="Your phone number"
                            />
                            <h3>Email</h3>
                            <input
                                type="text"
                                value={clientEmail}
                                onChange={(e) => setClientEmail(e.target.value)}
                            // placeholder="Your phone number"
                            />
                            <button
                                type="submit"
                                className="join-button"
                                disabled={!validatePhoneNumber(clientPhone) || !nonEmptyField(firstName) || !nonEmptyField(lastName) || !validateEmail(clientEmail)}
                            >
                                Register
                            </button>
                            {!validatePhoneNumber(clientPhone) && clientPhone && (
                                <p className="error-message">Entry not valid</p>
                            )}
                            <p
                                className="login-link"
                                style={{ cursor: 'pointer', textDecoration: 'none', marginTop: '30px' }}
                                onClick={() => setShowRegister(false)}
                            >
                                Back to Login
                            </p>
                        </form>
                    </div>
                )}
            </WebSocketProvider>
        );
    }

    return (
        <WebSocketProvider socketRef={socketRef} pendingImagesRef={pendingImages} messageQueueRef={messageQueueRef} setContacts={setContacts} setPendingInvites={setPendingInvites} userId={userId} sessionId={sessionId} setSessionId={setSessionId} clientPhone={clientPhone} threadId={currentThreadId} onThreadAdded={handleThreadAdded} joinThread={handleJoinedThread} clientThreads={threads} handleLoginResponse={handleLoginResponse} isLoggedIn={loggedIn}>
            <div className="app-container">
                <div
                    className={`side-drawer-container ${isMobile ? 'mobile' : ''} ${isDrawerOpen ? 'open' : ''}`}
                    style={{ width: `${drawerWidth}px` }}
                >
                    <SideDrawer
                        threads={threads}
                        currentThread={currentThreadId}
                        onThreadSelect={handleThreadSelect}
                        pendingInvites={pendingInvites}
                        onNewThread={handleNewThread}
                        onInviteAccept={handleAcceptInvite}
                        onInviteDecline={handleDeclineInvite}
                        closeDrawer={toggleDrawer}
                        onLogout={handleLogout}
                        setPendingInvites={setPendingInvites}
                    />
                </div>
                {isMobile && isDrawerOpen && (
                    <div className="backdrop" onClick={toggleDrawer}></div>
                )}
                {!isMobile && (
                    <div
                        className="resizer"
                        ref={resizerRef}
                        onMouseDown={handleMouseDown}
                    />
                )}
                <div className="chat-container">
                    {isMobile && !isDrawerOpen && (
                        <FontAwesomeIcon
                            icon={faBars}
                            className="toggle-drawer-icon"
                            onClick={toggleDrawer}
                        />
                    )}
                    <MessageList ref={messageListRef} sessionId={sessionId} currentThreadId={currentThreadId} userId={userId} contacts={contacts} sendInvite={handleSendInvite} />
                    <MessageInput clientPhone={clientPhone} firstName={firstName} sessionId={sessionId} currentThreadId={currentThreadId} />
                </div>
            </div>
        </WebSocketProvider>
    );
}

export default App;
